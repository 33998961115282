import ApiService from '@/services/ApiService';

const API_URL = '/api/Admin/ModuleSubscriptions/';

class AdminModuleSubscriptionService {
  getEdit(moduleSubscriptionId: string) {
    return ApiService.get(`${API_URL}Edit?moduleSubscriptionId=${moduleSubscriptionId}`);
  }

  update(moduleSubscription: { id: string }) {
    return ApiService.post(`${API_URL}Edit`, moduleSubscription);
  }

  create(moduleSubscription: { companyId: string; module: string }) {
    return ApiService.post(`${API_URL}Create`, moduleSubscription);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new AdminModuleSubscriptionService();
