var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"md":"9"}},[_c('DataTable',{attrs:{"items":_vm.moduleSubscriptions,"fields":[
        'shortId',
        'module',
        'company',
        'createdAt',
        {
          key: 'duration',
          label: _vm.$t('pages.admin.AdminModuleSubscriptionsIndex.duration'),
          sorter: false,
          filter: false,
        },
        {
          key: 'isActive',
          label: _vm.$t('pages.admin.AdminModuleSubscriptionsIndex.isActive'),
          sorter: false,
          filter: false,
        },
        {
          key: 'actions',
          label: '',
          _style: 'width:30%',
          sorter: false,
          filter: false,
        } ],"table-filter-value":_vm.tableFilterValue,"sorter-value":_vm.sorterValue,"loading":_vm.loading},on:{"update:tableFilterValue":function($event){_vm.tableFilterValue=$event},"update:table-filter-value":function($event){_vm.tableFilterValue=$event},"update:sorterValue":function($event){_vm.sorterValue=$event},"update:sorter-value":function($event){_vm.sorterValue=$event}},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.createdAt}})],1)]}},{key:"duration",fn:function(ref){
      var item = ref.item;
return [_c('td',[_c('FormatedTimestamp',{attrs:{"date":item.startedAt,"displayFormat":"P","showDistance":false}}),(item.endedAt)?_c('span',[_vm._v(" - "),_c('FormatedTimestamp',{attrs:{"date":item.endedAt,"displayFormat":"P"}})],1):_vm._e()],1)]}},{key:"isActive",fn:function(ref){
      var item = ref.item;
return [_c('td',[(item.isActive == 'True')?_c('span',[_vm._v("Ja")]):_vm._e(),(item.isActive == 'False')?_c('span',[_vm._v("Nein")]):_vm._e()])]}},{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('CButtonToolbar',[_c('router-link',{staticClass:"btn btn-primary btn-md float-right m-1",attrs:{"to":{
                name: 'AdminModuleSubscriptionEdit',
                params: { moduleSubscriptionId: item.id },
              }}},[_vm._v(" Details ")])],1)],1)]}}])}),_c('CPagination',{attrs:{"pages":_vm.pages,"activePage":_vm.currentPage},on:{"update:activePage":function($event){_vm.currentPage=$event},"update:active-page":function($event){_vm.currentPage=$event}}})],1),_c('CCol',{attrs:{"md":3}},[_c('CreateCard',{attrs:{"loading":_vm.loading,"errorMessage":_vm.message,"title":_vm.$t('pages.admin.AdminModuleSubscriptionIndex.createTitle'),"buttonEnabled":!!_vm.moduleSubscription.companyId && !!_vm.moduleSubscription.module},on:{"handleSubmit":_vm.handleSubmit}},[_c('ValidatedSelect',{attrs:{"translationKey":"moduleSubscription.module","field":_vm.$v.moduleSubscription.module,"options":_vm.availableModuleOptions,"value":_vm.$v.moduleSubscription.module.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.moduleSubscription.module, "$model", $event)}}}),_c('ValidatedSelect',{attrs:{"translationKey":"moduleSubscription.companyId","field":_vm.$v.moduleSubscription.companyId,"options":_vm.availableCompanieOptions,"value":_vm.$v.moduleSubscription.companyId.$model},on:{"update:value":function($event){return _vm.$set(_vm.$v.moduleSubscription.companyId, "$model", $event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }