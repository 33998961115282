
























import Vue from 'vue';

export default Vue.extend({
  props: {
    errorMessage: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      required: false,
      type: String,
    },
    buttonEnabled: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('handleSubmit');
    },
  },
});
