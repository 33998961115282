































































































import Vue from 'vue';
import AdminModuleSubscriptionService from '@/services/admin/AdminModuleSubscriptionService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import { MetaInfo } from 'vue-meta';
import { validationMixin } from 'vuelidate';
import CreateCard from '@/components/forms/CreateCard.vue';
import { required } from 'vuelidate/lib/validators';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ToastMessage from '@/models/ToastMessage';

class AdminModuleSubscriptionIndexDto {
  id!: string;
  shortId!: string;
  createdAt!: Date;
  module = '';
  companyId = '';
}

const validations = {
  moduleSubscription: {
    companyId: { required },
    module: { required },
  },
};

export default Vue.extend({
  components: { FormatedTimestamp, DataTable, ValidatedSelect, CreateCard },
  mixins: [validationMixin],
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminModuleSubscriptionIndex.meta.title').toString(),
    };
  },
  validations,
  data() {
    return {
      moduleSubscriptions: [] as AdminModuleSubscriptionIndexDto[],
      moduleSubscription: new AdminModuleSubscriptionIndexDto(),
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'CreatedAt', asc: false },
      currentPage: 1,
      loading: false,
      availableCompanies: {} as { [key: string]: string },
      availableModules: [] as string[],
      message: '',
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  computed: {
    availableModuleOptions(): { value: string; label: string }[] {
      const options = this.availableModules.map((m) => ({ value: m, label: m }));
      return [{ value: '', label: '' }, ...options];
    },
    availableCompanieOptions(): { value: string; label: string }[] {
      const options = Object.keys(this.availableCompanies).map((m) => ({
        value: m,
        label: this.availableCompanies[m],
      }));
      return [{ value: '', label: '' }, ...options];
    },
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminModuleSubscriptionIndex.breadcrumb.last' },
      ]);
    },

    loadData() {
      this.loading = true;
      AdminModuleSubscriptionService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.moduleSubscriptions = res.value.messages.items.map((m: AdminModuleSubscriptionIndexDto) => ({
          ...m,
          createdAt: new Date(m.createdAt),
        }));
        this.availableModules = res.value.availableModules;
        this.availableCompanies = res.value.availableCompanies;
        this.pages = res.value.messages.totalPages;
        this.setBreadCrumb();
      });
    },

    handleSubmit() {
      this.loading = true;
      return AdminModuleSubscriptionService.create(this.moduleSubscription).then(
        (resp) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.$router.push({
            name: 'AdminModuleSubscriptionEdit',
            params: { moduleSubscriptionId: resp.value },
          });
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },
  },
});
